import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { ROUTES } from '@routes';
import { isModeE2E } from '@utils';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  E2EInternalPortalUserProvider,
  InternalPortalUserProvider,
} from './InternalPortalUserProvider';

export function PortalUserProvider({ children }: PropsWithChildren) {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    const { pathname } = window.location;
    const returnToOrPathName = appState?.returnTo ?? pathname;

    if (returnToOrPathName === '/logout') {
      return navigate(ROUTES.dashboard);
    }

    return navigate(returnToOrPathName);
  };

  // !! bypassing auth in E2E mode
  if (isModeE2E) {
    return (
      <E2EInternalPortalUserProvider>{children}</E2EInternalPortalUserProvider>
    );
  }

  return (
    <Auth0Provider
      cacheLocation="localstorage"
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <InternalPortalUserProvider>{children}</InternalPortalUserProvider>
    </Auth0Provider>
  );
}
