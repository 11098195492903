const IS_SERVER = typeof window === 'undefined';

export function isServer() {
  return IS_SERVER;
}

export function isClient() {
  return !isServer();
}

export const isModeE2E = import.meta.env.MODE === 'e2e';
