import { GenericErrorPage } from '@components/errors/GenericError.page';
import { createBrowserRouter, RouteObject } from 'react-router-dom';
import App from '../App';

export const routes: RouteObject[] = [
  {
    id: 'dashboard',
    path: '/',
    lazy: async () => {
      const { DashboardPage } = await import('@pages/dashboard/page');
      return { Component: DashboardPage };
    },
  },
  {
    id: 'orders',
    path: 'orders',
    lazy: async () => {
      const { OrdersPage } = await import('@pages/orders/page');
      return { Component: OrdersPage };
    },
    children: [
      {
        id: 'orderDetails',
        path: ':orderId',
        lazy: async () => {
          const { OrderDetailsPage } = await import(
            '@pages/orders/OrderDetails.page'
          );
          return { Component: OrderDetailsPage };
        },
      },
    ],
  },
  {
    id: 'inventory',
    path: 'inventory',
    lazy: async () => {
      const { InventoryPage } = await import('@pages/inventory/page');
      return { Component: InventoryPage };
    },
  },
  {
    id: 'offers',
    path: 'offers',
    lazy: async () => {
      const { OffersPage } = await import('@pages/offers/page');
      return { Component: OffersPage };
    },
    children: [
      {
        id: 'offerGroupDetails',
        path: ':offerGroupPersistentId',
        lazy: async () => {
          const { OfferGroupDetailsPage } = await import(
            '@pages/offers/OfferGroupDetails.page'
          );
          return { Component: OfferGroupDetailsPage };
        },
      },
      {
        id: 'createOffer',
        path: 'create',
        lazy: async () => {
          const { CreateOfferPage } = await import(
            '@pages/offers/CreateOffer.page'
          );
          return { Component: CreateOfferPage };
        },
      },
      {
        id: 'createODDOffer',
        path: 'create/odd',
        lazy: async () => {
          const { CreateODDOfferPage } = await import(
            '@pages/offers/CreateODDOffer.page'
          );
          return { Component: CreateODDOfferPage };
        },
      },
      {
        id: 'createPersonalizationOffer',
        path: 'create/personalization',
        lazy: async () => {
          const { CreatePersonalizationOfferPage } = await import(
            '@pages/offers/CreatePersonalizationOffer.page'
          );
          return { Component: CreatePersonalizationOfferPage };
        },
      },
      {
        id: 'editOffer',
        path: ':offerGroupPersistentId/edit',
        lazy: async () => {
          const { EditOfferPage } = await import(
            '@pages/offers/EditOffer.page'
          );
          return { Component: EditOfferPage };
        },
      },
      {
        id: 'duplicateOffer',
        path: ':offerGroupPersistentId/duplicate',
        lazy: async () => {
          const { DuplicateOfferPage } = await import(
            '@pages/offers/DuplicateOffer.page'
          );
          return { Component: DuplicateOfferPage };
        },
      },
    ],
  },
  {
    id: 'products',
    path: 'products',
    lazy: async () => {
      const { ProductsPage } = await import('@pages/products/page');
      return { Component: ProductsPage };
    },
  },
];

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <GenericErrorPage height="h-screen" />,
    children: routes,
  },
]);

export default router;
