export function getAuthorizationParams(): Record<string, string> {
  const url = new URL(document.location.href);
  const authorizationParams: Record<string, string> = {};
  const invitation = url.searchParams.get('invitation');
  const organization = url.searchParams.get('organization');
  const organizationName = url.searchParams.get('organization_name');

  if (invitation && organization) {
    authorizationParams.invitation = invitation;
    authorizationParams.organization = organization;

    if (organizationName) {
      authorizationParams.organization_name = organizationName;
    }
  }

  return authorizationParams;
}
