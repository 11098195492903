import { withAuthenticationRequired } from '@auth0/auth0-react';
import { getAuthorizationParams, isModeE2E } from '@utils';
import { Outlet } from 'react-router-dom';
import { TopBarNavigationLayout } from './TopBarNavigationLayout';

function InternalLayout() {
  return (
    <TopBarNavigationLayout>
      <Outlet />
    </TopBarNavigationLayout>
  );
}

const InternalLayoutWithAuth = isModeE2E
  ? InternalLayout
  : withAuthenticationRequired(InternalLayout, {
      loginOptions: {
        get authorizationParams() {
          return getAuthorizationParams();
        },
      },
    });

export const AppLayout = () => <InternalLayoutWithAuth />;
