/**
 * Checks if the URLs are equal by comparing their string representations.
 * @param {string | URL} urlOrStr1 - first URL or string to compare.
 * @param {string | URL} urlOrStr2 - second URL or string to compare.
 * @returns {boolean} True if the URLs are equal, false otherwise.
 */
export const urlsEqual = (urlOrStr1: string | URL, urlOrStr2: string | URL) => {
  const url1 =
    urlOrStr1 instanceof URL
      ? urlOrStr1
      : new URL(urlOrStr1, document.location.href);

  const url2 =
    urlOrStr2 instanceof URL
      ? urlOrStr2
      : new URL(urlOrStr2, document.location.href);

  return url1.toString() === url2.toString();
};

/**
 * Capitalizes the first letter of a string.
 * @param str - The string to capitalize.
 * @returns The capitalized string.
 * @example 'hello world' => 'Hello world'
 */
export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Converts a string to a slug; replacing spaces with hyphens.
 * @param str - The string to convert.
 * @returns The slugified string
 * @example 'hello world' => 'hello-world'
 */
export const sluggify = (str: string): string => {
  return str.trim().toLowerCase().replace(/\s+/g, '-');
};

/**
 * Slices a string to the specified length.
 * @param str - The string to slice.
 * @param length - The number of characters to include in the sliced string.
 * @returns The sliced string.
 * @example 'hello world' => 'hello'
 */
export const sliceString = (str: string, length: number): string => {
  return str.slice(0, length);
};

/**
 * Converts a string to an integer, removes all decimals and non-numerical characters.
 * @param value The string value to convert.
 * @param isPositiveOnly (optional) If true, the result will be a positive integer.
 * @returns The converted integer value in the form of a string.
 * @example '1.12' => '1'
 * test21 => '21'
 * '-12test1' => '-121'
 */
export const toInteger = (value: string, isPositiveOnly?: boolean): string => {
  const int = Math.round(parseFloat(value.replace(/[^0-9.-]+/g, '')));

  if (isPositiveOnly) {
    return String(Math.abs(int));
  }

  return String(int);
};

/**
 * Pluralizes a word based on the given count, with an option to show a "+" for counts above a minimum.
 * @param {Object} options - The options for pluralization.
 * @param {number | null | undefined} options.count - The number of items.
 * @param {string} [options.word='item'] - The singular form of the word (default: 'item').
 * @param {number} [options.minCount] - The minimum count before showing a "+" (optional).
 * @param {number} [options.specificCount] - The specific count before showing a "+" (optional) -- you only want to use this if you want to show a specific count
 * @returns {string} A string with the count and properly pluralized word.
 * @example
 * pluralize({ count: 1 }) // '1 item'
 * pluralize({ count: 5, word: 'apple' }) // '5 apples'
 * pluralize({ count: 0 }) // '0 items'
 * pluralize({ count: 60, minCount: 50 }) // '50+ items'
 * pluralize({ count: 100, specificCount: 53 }) // '53+ items'
 */
export const pluralize = ({
  count,
  word = 'item',
  minCount,
  specificCount,
}: {
  count: number | null | undefined;
  word?: string;
  minCount?: number;
  specificCount?: number;
}): string => {
  if (count === undefined || count === null) {
    return `0 ${word}s`;
  }

  const itemCount = Math.abs(Number(count));

  if (itemCount === 0) {
    return `0 ${word}s`;
  }

  if (itemCount === 1) {
    return `1 ${word}`;
  }

  if (specificCount !== undefined) {
    return `${specificCount}+ ${word}s`;
  }

  if (minCount !== undefined && itemCount >= minCount) {
    return `${minCount}+ ${word}s`;
  }

  return `${itemCount} ${word}s`;
};

// TODO: https://linear.app/stylux/issue/ENG-271/better-human-readable-error-messages
export const convertGraphQLErrorToHumanReadable = (error?: string) => {
  const localError = error?.toLowerCase();
  if (localError?.includes('graphql')) {
    return 'Something went wrong, there has been a network error, please try again';
  }

  return error;
};

/**
 * Formats a string by converting it to lowercase and capitalizing only the first word.
 * @param {string} input - The string to format (can contain a mix of special characters).
 * @returns {string} The formatted string with the first character capitalized and the rest in lowercase.
 * @example 'Hello World' => 'Hello world'
 * 'hello' => 'Hello'
 * 'HELLO' => 'Hello'
 * 'HELLO WORLD' => 'Hello world'
 * 'hello_world' => 'Hello world'
 */
export const formatDisplayTextToCapitalize = (input: string): string => {
  // removes special characters
  const sanitizedInput = input.replace(/[^a-zA-Z0-9]+/g, ' ').trim();

  const lowercaseInput = sanitizedInput.toLowerCase();
  return lowercaseInput.charAt(0).toUpperCase() + lowercaseInput.slice(1);
};

/**
 * Normalizes text by removing special characters, lowercasing all, preserving spaces, and trimming.
 * @param text - The text to normalize, can be a string or an object with string values.
 * @returns The normalized text.
 * @example '555 Example Way Unit 555' => '555 example way unit 555'
 * @example {address1: '555 Example Way Unit 555', address2: null} => '555 example way unit 555'
 * @example { address1: '555 Example Way', address2: 'Unit 555'} => '555 example way unit 555'
 */
export const normalizeText = (
  text:
    | Record<string, string | number | null | undefined>
    | string
    | null
    | undefined,
): string => {
  if (text == null) {
    return '';
  }

  if (typeof text === 'string') {
    return text
      .replace(/[^a-zA-Z0-9\s]/g, '')
      .toLowerCase()
      .replace(/\s+/g, ' ')
      .trim();
  }

  return Object.values(text)
    .filter((value) => typeof value === 'string' || typeof value === 'number')
    .map((value) => {
      if (typeof value === 'number') {
        return value.toString();
      }
      return value
        .replace(/[^a-zA-Z0-9\s]/g, '')
        .toLowerCase()
        .replace(/\s+/g, ' ')
        .trim();
    })
    .join(' ')
    .trim();
};

/**
 * Adds an ordinal suffix to a number.
 * @param n - The number to add an ordinal suffix to.
 * @returns The number with an ordinal suffix.
 * @example 1 => '1st'
 * 2 => '2nd'
 * 3 => '3rd'
 * 4 => '4th'
 */
export const getNumberWithOrdinal = (n: number): string => {
  const j = n % 10;
  const k = n % 100;
  if (j === 1 && k !== 11) {
    return `${n}st`;
  }
  if (j === 2 && k !== 12) {
    return `${n}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${n}rd`;
  }
  return `${n}th`;
};
