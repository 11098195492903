import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  size?: number;
  className?: string;
}>;

export const StyluxLogo = ({ size = 32, className }: Props) => {
  return (
    <svg
      className={className}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512 512"
      preserveAspectRatio="xMidYMid meet"
      imageRendering="optimizeQuality"
    >
      <g>
        <path
          fill="#ec4812"
          d="M 114.5,-0.5 C 208.5,-0.5 302.5,-0.5 396.5,-0.5C 461.171,11.5039 499.504,49.8373 511.5,114.5C 511.5,208.5 511.5,302.5 511.5,396.5C 499.496,461.171 461.163,499.504 396.5,511.5C 302.5,511.5 208.5,511.5 114.5,511.5C 49.8294,499.496 11.4961,461.163 -0.5,396.5C -0.5,302.5 -0.5,208.5 -0.5,114.5C 11.5039,49.8294 49.8373,11.4961 114.5,-0.5 Z"
        />
      </g>
      <g>
        <path
          fill="#fff"
          d="M 247.5,88.5 C 275.978,87.436 303.645,91.6027 330.5,101C 358.361,112.866 371.361,134.033 369.5,164.5C 371.403,175.401 368.736,184.901 361.5,193C 355.683,195.198 350.017,194.865 344.5,192C 335.291,181.793 326.457,171.293 318,160.5C 302.868,142.518 283.701,131.684 260.5,128C 241.177,124.554 224.677,129.721 211,143.5C 197.634,168.853 203.801,188.019 229.5,201C 236.753,204.195 244.086,207.195 251.5,210C 274.112,216.902 296.446,224.568 318.5,233C 380.716,259.625 399.216,304.458 374,367.5C 356.799,393.882 332.632,410.549 301.5,417.5C 259.204,426.823 217.871,423.657 177.5,408C 136.952,389.741 121.118,358.574 130,314.5C 134.467,303.684 141.967,300.851 152.5,306C 160.716,315.881 168.549,326.048 176,336.5C 189.8,353.635 206.967,366.135 227.5,374C 244.659,379.123 261.992,379.79 279.5,376C 302.665,366.841 311.498,350.008 306,325.5C 303.37,318.865 299.203,313.365 293.5,309C 286.989,304.241 279.989,300.241 272.5,297C 248.343,288.059 224.343,278.726 200.5,269C 182.196,261.683 166.363,250.85 153,236.5C 136.679,212.968 132.346,187.301 140,159.5C 147.995,137.178 162.161,120.011 182.5,108C 202.923,96.9105 224.59,90.4105 247.5,88.5 Z"
        />
      </g>
    </svg>
  );
};
