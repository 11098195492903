import { BellIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import { IApiRole } from '@styluxinc/api-schema';
import { ReactNode } from 'react';
import { ROUTES } from '.';

export type NavigationItem = {
  name?: string;
  component?: ReactNode;
  href?: string;
  onClick?: () => void;
  icon?: ReactNode;
  roles?: IApiRole[] | null;
};

// TODO: add user navigation
export const userNavigation: NavigationItem[] = [
  {
    name: 'Merchant settings',
    href: '#',
    icon: <Cog6ToothIcon />,
  },
  {
    name: 'Alerts',
    href: '#',
    icon: <BellIcon />,
  },
  {
    name: 'My settings',
    href: '#',
  },
];

export const mainNavigation: NavigationItem[] = [
  {
    name: 'Dashboard',
    href: ROUTES.dashboard,
  },
  {
    name: 'Orders',
    href: ROUTES.orders,

    roles: [
      'Stylux Internal Super Admin',
      'SUPER_ADMIN',
      'THIRD_PARTY_SUPPORT_ORDERS',
    ],
  },
  {
    name: 'Inventory',
    href: ROUTES.inventory,
    roles: ['Stylux Internal Super Admin', 'SUPER_ADMIN'],
  },
  {
    name: 'Offers',
    href: ROUTES.offers,
    roles: ['Stylux Internal Super Admin', 'SUPER_ADMIN'],
  },
  {
    name: 'Products',
    href: ROUTES.products,
    roles: ['Stylux Internal Super Admin', 'SUPER_ADMIN'],
  },
];
