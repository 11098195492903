import { AppLayout } from '@components/layouts/AppLayout';
import { KeyboardShortcutProvider } from '@contexts/KeyboardShortcut/KeyboardShortcutProvider';
import { PreviousLocationProvider } from '@contexts/PreviousLocation/PreviousLocationProvider';
import { ReactNode } from 'react';
import { ThemeProvider } from './contexts/ThemeProvider';
import { UrqlClientProvider } from './contexts/UrqlClientProvider';
import { PortalUserProvider } from './contexts/User/PortalUserProvider';

const AppProviders = ({ children }: { children: ReactNode }) => (
  <PortalUserProvider>
    <PreviousLocationProvider>
      <UrqlClientProvider>
        <ThemeProvider>
          <KeyboardShortcutProvider>{children}</KeyboardShortcutProvider>
        </ThemeProvider>
      </UrqlClientProvider>
    </PreviousLocationProvider>
  </PortalUserProvider>
);

const App = () => {
  return (
    <AppProviders>
      <AppLayout />
    </AppProviders>
  );
};

export default App;
