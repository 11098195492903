import { useEffect } from 'react';

import {
  browserProfilingIntegration,
  browserTracingIntegration,
  captureConsoleIntegration,
  init,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
} from '@sentry/react';

import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

init({
  dsn: 'https://939981ad07e89915861947028f12f589@o4505501016719360.ingest.us.sentry.io/4507658510729216',
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /chrome-extension:\/\//i,
  ],
  integrations: [
    browserTracingIntegration(),
    browserProfilingIntegration(),

    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),

    replayIntegration({
      maskAllText: true,
      unmask: ['label'],
      blockAllMedia: true,
      unblock: ['svg'],
    }),

    captureConsoleIntegration({
      levels: ['error'],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  enabled: import.meta.env.MODE === 'production',
  environment: import.meta.env.VITE_DEPLOYMENT_ENV ?? 'dev',
});
