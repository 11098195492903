import { User as Auth0User, useAuth0 } from '@auth0/auth0-react';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { UserContext } from './UserContext';
import { User } from './user';

export function E2EInternalPortalUserProvider({ children }: PropsWithChildren) {
  const mockAuth0User: Auth0User = useMemo(
    () => ({
      name: 'E2E Test User',
      email: 'e2e@test.com',
      email_verified: true,
      'https://api.stylux.io/roles': ['Stylux Internal Super Admin'],
    }),
    [],
  );

  const mockUser = useMemo(
    () =>
      new User({
        name: mockAuth0User.name,
        userProfile: mockAuth0User,
      }),
    [mockAuth0User],
  );

  return (
    <UserContext.Provider value={mockUser}>{children}</UserContext.Provider>
  );
}

export function InternalPortalUserProvider({ children }: PropsWithChildren) {
  const { user: auth0User, isLoading } = useAuth0();

  const user = useMemo(() => new User(), []);

  useEffect(() => {
    user.name = auth0User?.name ?? user.name ?? null;
    user.userProfile = auth0User ?? user.userProfile ?? null;
  }, [user, auth0User]);

  if (isLoading) {
    return <></>;
  }

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}
