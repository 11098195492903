import formsPlugin from '@tailwindcss/forms';
import flowbitePlugin from 'flowbite/plugin';
import type { Config } from 'tailwindcss';

export const portalColors = {
  gray: {
    '50': '#F8FAFC',
    '100': '#F1F5F9',
    '200': '#E2E8F0',
    '300': '#CBD5E1',
    '400': '#94A3B8',
    '500': '#64748B',
    '600': '#475569',
    '700': '#334155',
    '800': '#1E293B',
    '900': '#0F172A',
    '950': '#020617',
  },
  success: {
    '50': '#F0FDF4',
    '100': '#DCFCE7',
    '200': '#BBF7D0',
    '300': '#86EFAC',
    '400': '#4ADE80',
    '500': '#22C55E',
    '600': '#16A34A',
    '700': '#15803D',
    '800': '#166534',
    '900': '#14532D',
    '950': '#052E16',
  },
  warning: {
    '50': '#FFFBEB',
    '100': '#FEF3C7',
    '200': '#FDE68A',
    '300': '#FCD34D',
    '400': '#FBBF24',
    '500': '#F59E0B',
    '600': '#D97706',
    '700': '#B45309',
    '800': '#92400E',
    '900': '#78350F',
    '950': '#451A03',
  },
  danger: {
    '50': '#FEF2F2',
    '100': '#FEE2E2',
    '200': '#FECACA',
    '300': '#FCA5A5',
    '400': '#F87171',
    '500': '#EF4444',
    '600': '#DC2626',
    '700': '#B91C1C',
    '800': '#991B1B',
    '900': '#7F1D1D',
    '950': '#450A0A',
  },
  info: {
    '50': '#EFF6FF',
    '100': '#DBEAFE',
    '200': '#BFDBFE',
    '300': '#93C5FD',
    '400': '#60A5FA',
    '500': '#3B82F6',
    '600': '#2563EB',
    '700': '#1D4ED8',
    '800': '#1E40AF',
    '900': '#1E3A8A',
    '950': '#172554',
  },
  orange: {
    '50': '#FFF7ED',
    '100': '#FFEDD5',
    '200': '#FED7AA',
    '300': '#FDBA74',
    '400': '#FB923C',
    '500': '#F97316',
    '600': '#EA580C',
    '700': '#C2410C',
    '800': '#9A3412',
    '900': '#7C2D12',
    '950': '#431407',
  },
  // custom colors that are not defined in tailwind
  product: {
    normal: {
      DEFAULT: '#67E8F9',
      hover: '#22D3EE',
    },
    light: {
      DEFAULT: '#CFFAFE',
      hover: '#A5F3FC',
    },
    dark: {
      DEFAULT: '#002B33',
      hover: '#00161A',
    },
  },
} as const;

const config: Config = {
  content: [
    './index.html',
    './src/**/*.{js,ts,jsx,tsx,mdx}',
    '../../node_modules/flowbite/**/*.js',
  ],
  darkMode: 'class',
  theme: {
    extend: {
      colors: portalColors,
    },
  },
  plugins: [flowbitePlugin, formsPlugin],
};

export default config;
